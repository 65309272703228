﻿import { on } from "./helpers/events/on.js";
import "./helpers/matches.js";
import { getAjax, postAjax } from "./helpers/ajax.js";
import Modal from "./modal.js";
import linkToInbox from "./link-to-inbox.js";

document.addEventListener('DOMContentLoaded', function () {

    var savedSearch = {

        myAccountButton: ".my-account-button",
        emailForm: "#userSubcription",
        emailTextBoxContainer: ".savedinput2",
        emailTextBox: "#savedemail",
        neverBounceContainer: ".override-email",
        overrideEmailCheckbox: "#overrideEmail",
        tooltip: ".ch-tooltip-msg",
        submitFormButton: "#saved-search-button",
        emailPlaceholderNeverBounce: "#enteredEmail",
        savedSearchExistsMessage: ".validation-exists",
        emailPlaceHolderSuccess: "#email-address-entered span",
        overrideEmailAddress: "#override-email-address",
        savedSearchSuccessMessage: ".saved2-success",
        sendFrequency: "#send-frequency",
        emailCheckBoxOverride: ".checkmark",
        query: ".query",
        triggerModalSelector: '#save-search-button',
        marketingOptedin: "#MarketingOptedin",
        lastRetrievedEmailAddress: "",

        goToMyAccount: function () {
            window.location = "/account/";
        },

        enableDisableSubscribeButton: function () {

            // check if email is valid or override selected
            var validEmailOrOverride = document.querySelector(savedSearch.overrideEmailCheckbox).matches(":checked") ||
                !document.querySelector(savedSearch.emailTextBoxContainer).classList.contains("invalid");

            if ((!document.querySelector(savedSearch.query)) && document.querySelector(savedSearch.sendFrequency).value && document.querySelector(savedSearch.emailTextBox).value !== "" && validEmailOrOverride) {
                document.querySelector(savedSearch.submitFormButton).disabled = false;
            }
            else {
                document.querySelector(savedSearch.submitFormButton).disabled = true;
            }
        },

        neverBounceValidation: function (callback) {

            var invalidClass = "invalid";
            var validClass = "valid";
            var queryClass = "query";
            var timeoutClass = "timeout";

            document.querySelector(savedSearch.emailTextBoxContainer).classList
                .remove(invalidClass, validClass, timeoutClass, queryClass);

            if (document.querySelector(savedSearch.emailTextBox).checkValidity()) {

                document.querySelector(savedSearch.emailTextBoxContainer).classList.add(queryClass);

                var emailAddress = document.querySelector(savedSearch.emailTextBox).value;

                var formData = {
                    emailAddress: emailAddress
                };

                var success = function (data) {
                    if (data === "true") {
                        document.querySelector(savedSearch.emailTextBoxContainer).classList.remove(queryClass);
                        document.querySelector(savedSearch.emailTextBoxContainer).classList.add(validClass);
                        document.querySelector(savedSearch.neverBounceContainer).style.display = 'none';

                    } else {
                        //Fails Neverbounce check
                        document.querySelector(savedSearch.emailTextBoxContainer).classList.remove(queryClass)
                        document.querySelector(savedSearch.emailTextBoxContainer).classList.add(invalidClass);

                        document.querySelector(savedSearch.neverBounceContainer).style.display = 'block';

                        document.querySelector(savedSearch.tooltip).style.display = 'block';
                        document.querySelector(savedSearch.tooltip).innerHTML = "Email address failed verification. <br> Please check that you have entered the correct email address.";
                    }

                    if (callback) {
                        callback();
                    }
                }

                postAjax('/service/ValidateEmailNeverBounce', formData, success);

            } else {
                document.querySelector(savedSearch.tooltip).display.style = 'block';
                document.querySelector(savedSearch.tooltip).innerHTML = "Email address failed verification. <br> Please check that you have entered the correct email address.";
                if (callback) {
                    callback();
                }
            }
        },

        afterSubscription: function (data) {

            if (data.ConfirmationCode) {

                if (data.AlreadySavedSearchForSubscriber) {
                    document.querySelector(savedSearch.savedSearchSuccessMessage).style.display = 'none';
                    document.querySelector(savedSearch.savedSearchExistsMessage).style.display = 'block';

                } else {
                    document.querySelector(savedSearch.emailForm).style.display = 'none';
                    document.querySelector(savedSearch.savedSearchExistsMessage).style.display = 'none';

                    const emailSuccess = document.querySelector(savedSearch.emailPlaceHolderSuccess);
                    if (emailSuccess) {
                        const emailAddress = document.querySelector(savedSearch.emailTextBox).value
                        emailSuccess.innerText = emailAddress;

                        // show a link to the user's inbox for gmail/hotmail/outlook users
                        const emailDomain = emailAddress.split("@")[1];
                        if (["gmail.com", "googlemail.com", "live.com", "hotmail.com", "outlook.com"].indexOf(emailDomain.toLowerCase()) > -1) {
                            document.getElementById("linktoinbox").innerHTML = linkToInbox.createLink(emailAddress);
                            document.getElementById("linktoinbox").style.display = 'block';
                        }
                    }
                    document.querySelector(savedSearch.savedSearchSuccessMessage).style.display = 'block';
                }
            }
        }
    }


    const ModalEvent = {
        HIDDEN: 'hidden.generic.modal',
        SHOWN: 'shown.generic.modal'
    }
    var modalElement = document.querySelector("#saved-search-modal");
    var submitHandler = null;

    on("click", ".save-search-button", function (event) {
        var target = event.target;

        // show modal
        Modal.init(modalElement, { showBackdrop: true })
        Modal.show(modalElement, target);

        // if the user is logged in then fetch their marketing opt-in status
        const emailAddress = document.querySelector(savedSearch.emailTextBox).value;
        if (emailAddress) {
            RefreshOptInStatus(emailAddress);
        }
    })

    if (modalElement) {
        modalElement.addEventListener(ModalEvent.SHOWN,
            function (event) {
                const currentModal = event.currentTarget;
                var modalTrigger = event.detail.relatedTarget;
                var data = null;

                submitHandler = function () {
                    // if modalTrigger is the button inside of trending panel, then get search params from its data- attributes
                    if (modalTrigger.classList.contains("save-search-button")) {
                        if (modalTrigger.classList.contains("trending")) {

                            const capType = modalTrigger.getAttribute("data-vehType"),
                                finType = modalTrigger.getAttribute("data-finType"),
                                man = modalTrigger.getAttribute("data-man"),
                                range = modalTrigger.getAttribute("data-range");

                            data = {
                                CapType: capType,
                                FinanceType: finType,
                                "Vehicles[0].Manufacturer": man,
                                "Vehicles[0].Range": range,
                                EmailAddress: document.querySelector(savedSearch.emailTextBox).value,
                                SendFrequency: document.querySelector(savedSearch.sendFrequency).value,
                                MarketingOptedin: document.querySelector(savedSearch.marketingOptedin).checked
                            }
                        } else {
                            const params = window["searchParams"];

                            data = {
                                CapType: params.vehicleType,
                                FinanceType: params.financeType,
                                "Vehicles[0].Manufacturer": params.manufacturer,
                                "Vehicles[0].Range": params.range,
                                "Vehicles[0].Models": params.model,
                                "Vehicles[0].Derivatives": params.derivative,
                                MonthsUpFront: params.deposit,
                                ContractLengths: params.term,
                                AnnualMileages: params.mileage,
                                MonthlyPriceFrom: params.minPrice,
                                MonthlyPriceTo: params.maxPrice,
                                InStockText: params.stockStatus,
                                ...(params.fuel !== "") && { FuelTypes: params.fuel },
                                ...(params.transmission !== "") && { Transmissions: params.transmission },
                                ...(params.bodyType !== "") && { BodyStyles: params.bodyType },
                                EngineSize: params.engineSize,
                                EmailAddress: document.querySelector(savedSearch.emailTextBox).value,
                                SendFrequency: document.querySelector(savedSearch.sendFrequency).value,
                                Seats: (params.seats === "0") ? "" : params.seats,
                                Doors: (params.doors === "0") ? "" : params.doors,
                                MaximumCO2: params.co2,
                                MinimumMPG: params.mpg,
                                ...(params.driveTrain !== "") && { DriveTrains: params.driveTrain },
                                MarketingOptedin: document.querySelector(savedSearch.marketingOptedin).checked
                            }
                        }
                    }

                    var success = function (response) {
                        var json = JSON.parse(response);
                        savedSearch.afterSubscription(json);
                    }
                    postAjax("/search/SubscribeToMailAlert", data, success);
                }

                // add event listener for submitting form
                document.querySelector(savedSearch.submitFormButton).addEventListener("click", submitHandler)

            }
        );

        modalElement.addEventListener(ModalEvent.HIDDEN,
            function (event) {
                // remove submitForm event listener
                document.querySelector(savedSearch.submitFormButton).removeEventListener("click", submitHandler)

                //reset form
                document.querySelector(savedSearch.savedSearchSuccessMessage).style.display = 'none';
                document.querySelector(savedSearch.savedSearchExistsMessage).style.display = 'none';
                document.querySelector(savedSearch.emailForm).style.display = 'block';
            }
        );
    }

    on("click", savedSearch.myAccountButton, savedSearch.goToMyAccount);

    on("click", savedSearch.overrideEmailCheckbox, function (e) {
        const email = document.querySelector(savedSearch.emailTextBox);

        if (e.target.matches(":checked")) {
            email.readOnly = true;
        } else {
            email.readOnly = false;
        }

        savedSearch.enableDisableSubscribeButton();
    });

    const RefreshOptInStatus = (emailAddress) => {
        // don't continue if we've retrieved the opt-in status for this email already
        if (savedSearch.lastRetrievedEmailAddress === emailAddress) {
            return;
        }

        getAjax("/account/marketing-optin-status?emailAddress=" + emailAddress, (optInStatus) => {
            // update the opt-in checkbox
            document.querySelector(savedSearch.marketingOptedin).checked = optInStatus === "OptIn";

            // update the last retrieved email address
            savedSearch.lastRetrievedEmailAddress = emailAddress;
        });
    }

    on("focusout", savedSearch.emailTextBox, (e) => {
        // fetch the user's marketing opt-in status when an email address has been entered
        const emailAddress = e.target.value;
        RefreshOptInStatus(emailAddress);
    });

    ///////////////////////// validation ////////////////////////////////////////

    let emailForm = document.querySelector(savedSearch.emailForm);

    if (emailForm) {
        emailForm.addEventListener("submit",
            function (e) {
                e.preventDefault();
            }
        );

        document.querySelector(savedSearch.emailTextBox).addEventListener("focusout", function () {
            if (document.querySelector(savedSearch.emailTextBox).matches("[readonly]")) {
                return;
            }

            document.querySelector(savedSearch.submitFormButton).disabled = true;

            if (document.querySelector(savedSearch.emailForm).checkValidity() && !document.querySelector(savedSearch.overrideEmailCheckbox).matches(":checked")) {
                savedSearch.neverBounceValidation(function () {
                    savedSearch.enableDisableSubscribeButton();
                });
            }

            if (document.querySelector(savedSearch.emailTextBox).checkValidity()) {
                const emailError = document.querySelector(savedSearch.emailTextBox + " + label.error");
                emailError.textContent = '';
            } else {
                showError();
            }
        });

        document.querySelector(savedSearch.sendFrequency).addEventListener("change", function () {
            savedSearch.enableDisableSubscribeButton();
        });
    }

    function showError() {
        const email = document.querySelector(savedSearch.emailTextBox);
        const emailError = document.querySelector(savedSearch.emailTextBox + " + label.error");

        if (email.validity.valueMissing) {
            emailError.textContent = 'This field is required.';
        } else if (email.validity.typeMismatch) {
            emailError.textContent = 'Please enter a valid email address.';
        }
    }

});