﻿
const updateSearchResults = (pageNumber, sort, afterUpdate) => {
    const currentPage = window["landingPageSlug"] ? window["landingPageSlug"] : "low-cost-car-leasing";

    // fetch the updated search results
    $.get(`/cars/${currentPage}/search-results-partial?pageNumber=${pageNumber}&sort=${sort}`, function (data) {
        // update the search results container with the HTML returned from the server
        document.getElementById("search-results-container").innerHTML = data;

        // callback
        afterUpdate();

        // rebind the pagination links now they've been replaced
        bindPaginationLinkEvents();
    });
}

const sortDropdown = document.querySelector("#select-sort");
if (sortDropdown) {
    sortDropdown.onchange = function (event) {
        const selectSort = event.currentTarget;

        // fetch search results for the new sort order
        const sortParam = selectSort[selectSort.selectedIndex].value;

        const pageNumber = 1;
        updateSearchResults(pageNumber, sortParam, () => {
            // update the querystring params to include the updated sort order
            const querystringParams = new URLSearchParams(location.search);
            querystringParams.set("sort", sortParam);
            const urlPath = window["landingPageSlug"] ? `/cars/${window["landingPageSlug"]}/` : "/low-cost-car-leasing/";
            window.history.pushState({ sort: sortParam, pageNumber }, "", `${urlPath}?${querystringParams}`);
        });
    }
}

const bindPaginationLinkEvents = () => {
    document.querySelectorAll(".pagination a").forEach((paginationLink) => {
        paginationLink.addEventListener("click", (event) => {
            event.preventDefault();

            // fetch search results for the new page
            const selectSort = document.querySelector("#select-sort");
            const sort = selectSort[selectSort.selectedIndex].value;
            const pageNumber = event.target.getAttribute("destination-page-number");

            updateSearchResults(pageNumber, sort, () => {
                // update current URL to that of the new page
                const destinationPageUrl = event.target.getAttribute("href");
                window.history.pushState({ sort, pageNumber }, "", destinationPageUrl);

                // scroll back to the top of the search results
                document.querySelector(".deal-panel").scrollIntoView();
            });
        });
    });
}

window.addEventListener("popstate", (event) => {
    // back/forward buttons have been used - update the search results
    updateSearchResults(event.state.pageNumber, event.state.sort, () => {
        // update sort order dropdown selection 
        const selectSort = document.querySelector("#select-sort");
        selectSort.value = event.state.sort;

        // scroll back to the top of the search results
        document.querySelector(".deal-panel").scrollIntoView();
    });

});

// bind the pagination link events
bindPaginationLinkEvents();

// setup initial state
const selectSort = document.querySelector("#select-sort");
if (selectSort) {
    const initialSort = selectSort[selectSort.selectedIndex].value;
    window.history.replaceState({ sort: initialSort, pageNumber: 1 }, "");
}
